import React from "react";
import useMobileScreen from "useMobileScreen";

const randomGap = () => {
  const minGap = 1; // in rem
  const maxGap = 18; // in rem

  /**
   * 
   * @param {Number} min 
   * @param {Number} max 
   * @returns {number}
   */
  const randomValue = (min, max) =>
    Math.floor(Math.random() * (max - min + 0.25)) + min;

  // Generate random margins for each side
  const top = randomValue(minGap, maxGap);
  const right = randomValue(minGap, maxGap);
  const bottom = randomValue(minGap, maxGap);
  const left = randomValue(minGap, maxGap);

  return `${top}rem ${right}rem ${bottom}rem ${left}rem`;
};

//TODO: Open image to fullscreen when clicking

const PhotoCard = ({ title, url }) => {
  return (
    <div
      className="shadow"
      style={{
        display: "inline-flex",
        padding: "1rem",
        margin: useMobileScreen() ? "0.5rem 1rem" : randomGap(),
      }}
    >
      <img
        src={url}
        alt={title}
        style={{ objectFit: "cover", maxHeight: useMobileScreen() ? "" : "60vh", width: "100%" }}
      />
    </div>
  );
};

export default PhotoCard;
