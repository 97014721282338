import axios from "../../node_modules/axios/index";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_URL,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`
  },
});

export default apiClient;
