// Gallery.js
import React, { useEffect, useState } from "react";
import PhotoCard from "../components/PhotoCard";
import { getImagesByCollection } from "services/StrapiService";
import { useParams } from "../../node_modules/react-router-dom/dist/index";
import useMobileScreen from "useMobileScreen";

const Gallery = () => {
  const [images, setImages] = useState([]);
  const { collectionTitle } = useParams();

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (collectionTitle) {
          const data = await getImagesByCollection(collectionTitle);
          setImages(data.data[0]?.images);
        }
      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    };

    fetchData();
  }, [collectionTitle]);

  const handleRandomize = () => {
    const shuffledImages = shuffleArray([...images]);
    setImages(shuffledImages);
  };

  return (
    <div style={{position: "relative"}}>
      <button
        onClick={(e) => {
          e.preventDefault();
          handleRandomize();
        }}
        style={{
          position: "absolute",
          right: "1rem",
          top: "0",
          marginTop: "-1.5rem",
        }}
      >
        Shuffle
      </button>
      <div
        style={{ display: useMobileScreen() ? "block" : "none", color: "gray", paddingTop: "2rem" }}
      >
        <p style={{ paddingLeft: "1rem", fontSize: "0.75rem" }}>
          Note: Use desktop device to get full potential of this installation
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}
      >
        {images?.map((image) => (
          <PhotoCard
            key={image.id}
            title={image.name}
            url={`https://api.elmerikinnunen.com${image.formats.large?.url}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
