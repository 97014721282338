import React, { useEffect, useState } from "react";
import { NavLink } from "../../node_modules/react-router-dom/dist/index";
import { getAllCollections } from "services/StrapiService";

const Home = () => {
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllCollections();
        setCollections(response.data);
      } catch (e) {
        console.error("Failed to fetch collections:", e);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
    <div className="home-nav" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
      {collections?.map((collection) => (
        <NavLink
          className="shadow"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
            margin: "1rem",
            cursor: "pointer",
          }}
          to={`/collection/${collection.title}`}
            state={{ currentPage: collection.title }}
        >
          <img
            src={`https://api.elmerikinnunen.com${collection.images[0].formats.small.url}`}
            alt={collection.title}
            style={{width: "100%"}}
          />
          <p>{collection.title}</p>
        </NavLink>
      ))}
    </div>
    </div>
  );
};

export default Home;
