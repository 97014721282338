import apiClient from "./apiClient";

/**
 * Fetch all data with full details.
 * @returns {Promise} API response with full data and populated relations.
 */
export const getAllCollections = async () => {
  try {
    const response = await apiClient.get(`/api/collections?populate=*`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

/**
 * Fetch all collection titles.
 * @returns {Promise} API response with only collection titles.
 */
export const getCollections = async () => {
  try {
    const response = await apiClient.get(`/api/collections?fields=title`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

/**
 * Fetch all images by collection name.
 * @param {string} collectionName - The name of the collection.
 *
 * @returns {Promise} API response with images for the specified collection.
 */
export const getImagesByCollection = async (collectionName) => {
  try {
    const response = await apiClient.get(
      `/api/collections?filters[title][$eq]=${collectionName}&populate=*`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
