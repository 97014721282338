import "./App.css";
import Gallery from "pages/Gallery";
import Home from "pages/Home";
import React from "react";
import { Analytics } from "../node_modules/@vercel/analytics/dist/react/index";
import {
  BrowserRouter,
  Route,
  Routes,
} from "../node_modules/react-router-dom/dist/index";
import CollectionList from "./components/CollectionList";

function App() {
  return (
    <BrowserRouter>
      <CollectionList />
      <Routes> //TODO mobile menu navigation
        <Route path="/" element={<Home />} />
        <Route path="collection/:collectionTitle" element={<Gallery />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  );
}

export default App;
