// CollectionList.js
import React, { useEffect, useState } from "react";
import { getCollections } from "services/StrapiService";
import { NavLink } from "../../node_modules/react-router-dom/dist/index";
import { FontAwesomeIcon } from "../../node_modules/@fortawesome/react-fontawesome/index";
import { faBars } from "../../node_modules/@fortawesome/free-solid-svg-icons/index";
import useMobileScreen from "useMobileScreen";

const CollectionList = () => {
  const [collections, setCollections] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const collections = await getCollections();
        setCollections(collections.data);
      } catch (e) {
        console.error("Failed to fetch collections:", e);
      }
    };
    fetchData();
  }, []);

  const toggleMobileMenu = async () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  return (
    <div>
      <FontAwesomeIcon
        icon={faBars}
        style={{
          display: useMobileScreen() ? "block" : "none",
          margin: "1rem",
          fontSize: "1.5rem",
        }}
        onClick={toggleMobileMenu}
      />
      <div
        className={`${useMobileScreen() ? "mobile-nav" : "nav"} ${
          isMobileMenuOpen ? "open" : ""
        }`}
      >
        <NavLink
          to="/"
          state={{ currentPage: "home" }}
          key={"home"}
          onClick={toggleMobileMenu}
        >
          Home
        </NavLink>
        {collections?.map((collection) => (
          <NavLink
            to={`/collection/${collection.title}`}
            state={{ currentPage: collection.title }}
            key={collection.title}
            onClick={toggleMobileMenu}
          >
            {collection.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default CollectionList;
